<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-select
            v-model="query.mchId"
            placeholder="商户名称"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="v.mchName"
              :value="v.mchId"
              v-for="v in mchDrop"
              :key="v.mchId"
            ></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>终端IP地区</i>
          <el-input
            v-model="query.ipword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="终端IP地区"
          ></el-input>
        </div>
        <div class="item">
          <i>产品类型</i>
          <el-select
            v-model="query.productId"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              :label="v.productName"
              :value="v.productId"
              v-for="v in payproductsDrop"
              :key="v.productId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>统计日期</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd"         
            type="date"
            placeholder="选择日期"         
          > <!-- :clearable="false" :editable="false" :picker-options="startDatePicker" -->
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd"
            type="date"        
            placeholder="选择日期"          
          > <!-- :clearable="false" :editable="false"   :picker-options="endDatePicker"-->
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button class="searchBt" @click="getLists">查询</el-button>
          <el-button @click="outputShow" class="outputBt" v-auth="'DATA:ANALYSIS:IP/EXPORT'">导出</el-button>
        </div>      
      </div>
      <div class="clear"> 
        <div class="header_switch">
          <div
            :class="{ right: true, active: tabActive == 1 ? true : false }"
            @click="setTabActive(1)"
          >
            终端IP明细
          </div>
          <div
            :class="{ right: true, active: tabActive == 0 ? true : false }"
            @click="setTabActive(0)"
          >
            终端IP统计
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="map" v-show="tabActive == 0"></div> -->
    <div v-show="tabActive == 0">
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>终端IP地区</p>
            </td>
            <td>
              <p>
                <span>地区占比</span>
                <i
                  @click="setOrder"
                  class="el-icon-d-caret"
                  style="margin-left: 10px"
                ></i>
              </p>
            </td>
            <td>
              <p>地区订单总数</p>
            </td>
            <td>
              <p>地区订单金额(元)</p>
            </td>
            <td>
              <p>地区成功订单</p>
            </td>
            <td>
              <p>地区交易金额(元)</p>
            </td>
          </tr>
          <tr v-for="(v, i) in orderStatistics" :key="i">
            <td>
              <p>{{ v.ipAddress }}</p>
            </td>
            <td>
              <p>{{ v.successRate }}%</p>
            </td>
            <td>
              <p>{{ v.tradeNum }}</p>
            </td>
            <td>
              <p>{{ (v.tradeAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.successTradeNum }}</p>
            </td>
            <td>
              <p>{{ (v.successTradeAmount / 100) | formatMoney }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-show="tabActive == 1">
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
              <p>交易时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>商户订单号</p>
              <p>交易流水后</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>订单状态</p>
            </td>
            <td>
              <p>交易金额(元)</p>
            </td>
            <td>
              <p>终端IP</p>
            </td>
            <td>
              <p>终端IP地区</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.tradeTime }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.mchOrderNo }}</p>
              <p>{{ v.plfOrderNo }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ orderStatus[v.orderStatus] }}</p>
            </td>
            <td>
              <p>{{ (v.orderAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.spbillCreateIp }}</p>
            </td>
            <td>
              <p>{{ v.ipAddress }}</p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50,100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <div class="hover-box" v-if="showHoverBox" ref="hoverBox">
      <div class="hover-box-item">
        <span>终端IP地区:</span>
        {{ showForm.ipAddress }}
      </div>
      <div class="hover-box-item">
        <span>地区占比:</span>
        {{ showForm.successRate }}%
      </div>
      <div class="hover-box-item">
        <span>当前地区订单总数:</span>
        {{ showForm.tradeNum }}笔
      </div>
      <div class="hover-box-item">
        <span>当前地区成功订单:</span>
        {{ showForm.successTradeNum }}笔
      </div>
      <div class="hover-box-item">
        <span>当前地区订单金额:</span>
        {{ (showForm.tradeAmount / 100) | formatMoney }}元
      </div>
      <div class="hover-box-item">
        <span>当前地区交易金额:</span>
        {{ (showForm.successTradeAmount / 100) | formatMoney }}元
      </div>
    </div>

    <el-dialog title="导出终端IP分析" :visible.sync="outPayShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">商户名称</div>
          <el-select v-model="outputForm.mchId" placeholder="选择商户">
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            ></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <el-select
            v-model="selectTimeType"
            @change="timeChange"
            placeholder="选择交易日期"
          >
            <!-- <el-option label="今天" value="1"></el-option> -->
            <el-option label="昨天" value="2"></el-option>
            <el-option label="近7天" value="7"></el-option>
            <el-option label="其它" value="-1"></el-option>
          </el-select>
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <el-date-picker
            style="width: 200px; height: 36px"
            :picker-options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </el-date-picker>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="outPayShow = false">取 消</el-button>
        <el-button class="addBt" @click="output">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import AMap from "AMap";
import { merchantsDrop, payproductsDrop } from "@/api/common.js";
import { ipStatistics, exportIp } from "@/api/data/data.js";
import { mapState, mapMutations } from "vuex"; 
export default {
  data() {
    let that = this;
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        startTime: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        endTime: formatDate(+new Date() - 24 * 60 * 60 * 1000, "yyyy-MM-dd"),
        productId: "",
        mchId: "",
        ipword: "",
      },
      tabData: [],
      orderStatistics: [],
      totalNum: 0,
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      mchDrop: [],
      payproductsDrop: [],
      orderStatus: {
        NOTPAY: "未支付",
        SUCCESS: "支付成功",
        REFUND: "转入退款",
        CLOSED: "订单关闭",
        PAYERROR: "支付失败",
        USERPAYING: "用户支付中",
        REVOKED: "已撤销",
      },
      startDatePicker: this.startDate(),
      endDatePicker: this.endData(),
      map: null,
      showHoverBox: false,
      totalTradeAmount: 0,
      totalTradeNum: 0,
      showForm: {},
      tabActive: 0,
      outPayShow: false,
      outputForm: {},
      selectTimeType: "",
    };
  },
  computed: {
    ...mapState({
      data_nalysis_ipfx: "data_nalysis_ipfx",
    }),
  },
  created() {
    if (this.data_nalysis_ipfx) {
      this.query = this.data_nalysis_ipfx.query;
    }
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },
  mounted() {
    // this.initMap();
  },
  methods: {
    ...mapMutations({
      data_setnalysis_ipfx: "data_setnalysis_ipfx",
    }),
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
        mchId: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$message.error("导出时间段必选");
        return;
      }
      let data = this.outputForm;
      exportIp(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute(
            "download",
            this.outputForm.mchId
              ? this.outputForm.mchId + "_终端IP统计.csv"
              : "终端IP统计.csv"
          );
          document.body.appendChild(link);
          link.click();
        }
      });
      this.outPayShow = false;
    },
    initMap() {
      this.map = new AMap.Map("map");
    },
    setTabActive(e) {
      this.tabActive = e;
    },
    searchMap() {
      let opts = {
        subdistrict: 0, //获取边界不需要返回下级行政区
        extensions: "all", //返回行政区边界坐标组等具体信息
        level: "city", //查询行政级别为 市
      };
      let city = new AMap.DistrictSearch(opts);
      this.map.clearMap();
      let polygons = [];
      let index = 0;
      const loading = this.$loading({
        lock: true,
        background: "rgba(255, 255, 255, 0.5)",
      });
      this.orderStatistics.forEach((val, idx) => {
        let otherList = ["其他", null];
        if (otherList.indexOf(val.ipAddress) === -1) {
          city.search(val.ipAddress, (status, result) => {
            index++;
            let bounds =
              result &&
              result.districtList &&
              result.districtList[0].boundaries;
            if (bounds) {
              for (var i = 0, l = bounds.length; i < l; i++) {
                //生成行政区划polygon
                let polygon = new AMap.Polygon({
                  strokeWeight: 1,
                  path: bounds[i],
                  fillOpacity: 0.9,
                  fillColor: this.getColor(val.successRate),
                  strokeColor: "#CC66CC",
                });
                polygon.on("click", (e) => {
                  this.showForm = val;
                  this.showHoverBox = true;
                  this.$nextTick(() => {
                    this.$refs.hoverBox.style.left =
                      e.originEvent.clientX + "px";
                    this.$refs.hoverBox.style.top =
                      e.originEvent.clientY + "px";
                  });
                });
                polygons.push(polygon);
              }
              if (this.orderStatistics.length <= index || index % 100 == 0) {
                let overlayGroup = new AMap.OverlayGroup(polygons);
                this.map.add(overlayGroup);
                if (this.orderStatistics.length <= index) {
                  setTimeout(() => {
                    loading.close();
                  }, 1000);
                }
              }
              this.map.setFitView();
            }
          });
        } else {
          index++;
          this.map
            .on("click", (e) => {
              this.showForm = val;
              this.showHoverBox = true;
              this.$nextTick(() => {
                this.$refs.hoverBox.style.left = e.originEvent.clientX + "px";
                this.$refs.hoverBox.style.top = e.originEvent.clientY + "px";
              });
            })
            .on("mouseout", () => {
              this.showHoverBox = false;
            });
        }
      });
    },
    getColor(rate) {
      if (rate > 0 && rate <= 10) return "#e6e6ff";
      if (rate > 10 && rate <= 20) return "#ccccff";
      if (rate > 20 && rate <= 30) return "#b3b3ff";
      if (rate > 30 && rate <= 40) return "#9a99ff";
      if (rate > 40 && rate <= 50) return "#8080ff";
      if (rate > 50 && rate <= 60) return "#6766ff";
      if (rate > 60 && rate <= 70) return "#4d4dff";
      if (rate > 70 && rate <= 80) return "#3433ff";
      if (rate > 80 && rate <= 90) return "#1b19ff";
      if (rate > 90 && rate <= 100) return "#0100ff";
      return "#0100ff";
    },
    // 获取下拉
    getDrop() {
      payproductsDrop().then((res) => {
        if (res) {
          this.payproductsDrop = res.resultData;
        }
      });
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 排序
    setOrder() {
      this.query.orderBy =
        this.query.orderBy == ""
          ? "ASC"
          : this.query.orderBy == "ASC"
          ? "DESC"
          : "ASC";
      this.getLists();
    },
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.showHoverBox = false;
      if (!this.query.startTime || !this.query.endTime) {
        this.$message.error("统计日期时间段必选");
        return;
      }
      ipStatistics(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.orders;
          this.orderStatistics = res.resultData.orderStatistics;
          this.totalTradeAmount = res.resultData.totalTradeAmount;
          this.totalTradeNum = res.resultData.totalTradeNum;
          this.totalNum = res.resultData.totalNum;
          // this.$nextTick(() => {
          //   this.searchMap();
          // });
        }
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    startDate() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.query.endTime
            ? !(
                new Date(vm.query.endTime).getTime() >= time.getTime() &&
                time.getTime() >
                  new Date(vm.query.endTime).getTime() -
                    14 * 24 * 60 * 60 * 1000
              )
            : true;
        },
      };
    },
    endData() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.query.startTime
            ? !(
                new Date(vm.query.startTime).getTime() <= time.getTime() &&
                time.getTime() <
                  new Date(vm.query.startTime).getTime() +
                    14 * 24 * 60 * 60 * 1000
              )
            : true;
        },
      };
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.data_setnalysis_ipfx(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{
  width: 48.284%;
}
.con_from .lineP .item i {
  width: 74px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 74px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 74px - 24.67px - 10px) / 2);
}
.jiaoyi_box{
  width: 49%;
}
#map {
  width: 100%;
  height: 450px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.hover-box {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  width: 200px;
  padding: 24px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.2s;
}
.hover-box-item {
  font-size: 12px;
}
.hover-box-item + .hover-box-item {
  margin-top: 8px;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}
.overAccount_ul_title i {
  color: #48b8b6;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
</style>
